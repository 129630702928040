import { FaucetState } from './faucet';
import { configureStore } from './configureStore';

const { store, persistor } = configureStore();

export interface ApplicationState {
    faucet: FaucetState;
}

export * from './configureStore';

export { store, persistor };
