import { ActionCreator } from 'redux';
import {
    FaucetAPIStatus,
    NetworkBalances,
    UPDATE_STATUS,
    UPDATE_BALANCES,
    UPDATE_TRANSACTIONS,
    UpdateStatusAction,
    UpdateBalancesAction,
    UpdateTransactionsAction
} from './types';

export const updateStatus: ActionCreator<UpdateStatusAction> = (status: FaucetAPIStatus) => ({
    type: UPDATE_STATUS,
    status
});

export const updateBalances: ActionCreator<UpdateBalancesAction> = (balances: NetworkBalances) => ({
    type: UPDATE_BALANCES,
    balances
});

export const updateTransactions: ActionCreator<UpdateTransactionsAction> = (
    transactions: number
) => ({
    type: UPDATE_TRANSACTIONS,
    transactions
});
