import { WrapRootElementBrowserArgs } from 'gatsby';
import React, { ReactElement, Fragment } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../../src/store';
import { ConfigProvider } from 'antd';

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs): ReactElement => {
    const isSSR = typeof window === 'undefined';

    return (
        <Fragment>
            <Provider store={store}>
                {isSSR ? (
                    <Fragment>{element}</Fragment>
                ) : (
                    <PersistGate persistor={persistor}>
                        <ConfigProvider>{element}</ConfigProvider>
                    </PersistGate>
                )}
            </Provider>
        </Fragment>
    );
};
