import { Action } from 'redux';
import { FAUCET_NETWORK } from '../../data/faucet';

export enum FaucetAPIStatus {
    UNKNOWN,
    ONLINE,
    OFFLINE
}

export type NetworkBalances = { [key in FAUCET_NETWORK]?: string };

export interface FaucetState {
    status: FaucetAPIStatus;
    totalTransactions: number;
    balances: NetworkBalances;
    statusUpdated: number;
    balancesUpdated: number;
    transactionsUpdated: number;
}

export const UPDATE_STATUS = 'UPDATE_STATUS';
export interface UpdateStatusAction extends Action {
    type: typeof UPDATE_STATUS;
    status: FaucetAPIStatus;
}

export const UPDATE_BALANCES = 'UPDATE_BALANCES';
export interface UpdateBalancesAction extends Action {
    type: typeof UPDATE_BALANCES;
    balances: NetworkBalances;
}

export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';
export interface UpdateTransactionsAction extends Action {
    type: typeof UPDATE_TRANSACTIONS;
    transactions: number;
}

export type FaucetActions = UpdateStatusAction | UpdateBalancesAction | UpdateTransactionsAction;
