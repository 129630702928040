import { Reducer } from 'redux';
import {
    FaucetAPIStatus,
    UPDATE_STATUS,
    UPDATE_BALANCES,
    UPDATE_TRANSACTIONS,
    FaucetActions,
    FaucetState
} from './types';

const INITIAL_STATE: FaucetState = {
    status: FaucetAPIStatus.UNKNOWN,
    balances: {},
    totalTransactions: -1,
    statusUpdated: 0,
    balancesUpdated: 0,
    transactionsUpdated: 0
};

export const reducer: Reducer<FaucetState, FaucetActions> = (
    state = INITIAL_STATE,
    action
): FaucetState => {
    switch (action.type) {
        case UPDATE_STATUS:
            return {
                ...state,
                status: action.status,
                statusUpdated: Date.now()
            };
        case UPDATE_BALANCES:
            return {
                ...state,
                balances: action.balances,
                balancesUpdated: Date.now()
            };
        case UPDATE_TRANSACTIONS:
            return {
                ...state,
                totalTransactions: action.transactions,
                transactionsUpdated: Date.now()
            };
        default:
            return state;
    }
};
