import { combineReducers } from 'redux';
import { ApplicationState } from './index';
import { faucetReducer, FaucetActions } from './faucet';

export type ApplicationActions = FaucetActions;

const rootReducer = combineReducers<ApplicationState>({
    faucet: faucetReducer
});

export default rootReducer;
